<template>
  <img
    loading="lazy"
    decoding="async"
    draggable="false"
    :class="{cover, loaded}"
    :src="src"
    :width="_width"
    :height="_height"
    @load="loaded = true; $emit('load')"
  />
</template>

<script>
import { optimizedImageURL, getWidth, getHeight } from '@/services/OptimizedImage'

export default {
  name: 'ResponsiveImage',
  props: {
    data: { type: Object, required: true },
    options: { type: Object, default: () => {} },
    width: { type: Number, default: 1200 },
    cover: { type: Boolean }
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    _width () {
      return getWidth(this.data)
    },
    _height () {
      return getHeight(this.data)
    },
    src () {
      return optimizedImageURL(this.data, { width: this.width, ...this.options })
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  opacity: 0;
  transition: opacity 0.3s;
  user-select: none;

  &.loaded {
    opacity: 1;
  }
}

.cover {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
</style>
