<template>
  <VueAccessibleSelect ref="select" v-bind="$attrs" v-on="$listeners">
    <template v-if="!$scopedSlots.arrow" v-slot:arrow>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.248 7.003">
        <path d="M6.126 4.892 10.757.254a.872.872 0 0 1 1.236 0 .883.883 0 0 1 0 1.24L6.746 6.745a.874.874 0 0 1-1.207.026L.255 1.501A.875.875 0 1 1 1.491.261Z"/>
      </svg>
    </template>
    <slot v-if="!$scopedSlots.arrow" :name="'arrow'">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.248 7.003">
        <path d="M6.126 4.892 10.757.254a.872.872 0 0 1 1.236 0 .883.883 0 0 1 0 1.24L6.746 6.745a.874.874 0 0 1-1.207.026L.255 1.501A.875.875 0 1 1 1.491.261Z"/>
      </svg>
    </slot>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </VueAccessibleSelect>
</template>

<script>
import { VueAccessibleSelect } from 'vue-accessible-select'

export default {
  name: 'StyledSelect',
  inheritAttrs: false,
  components: {
    VueAccessibleSelect
  }
}
</script>

<style lang="scss" scoped>

::v-deep .v-select {
  &__selected {
    padding-right: rem(4px);

    font-size: rem(14px);
  }

  &__label {
    display: inline-block;

    margin-bottom: rem(8px);

    font-size: rem(14px);
  }

  &__btn {
    display: flex;

    width: 100%;

    align-items: center;

    padding: rem(2px 8px 4px);

    border: 2px solid #ced4da;
    border-radius: rem(4px);

    // background-color: #fff;
    font-size: 100%;

    transform: translateZ(0);

    &:focus {
      border-color: #80bdff;

      box-shadow: 0 0 0 4px rgba(0, 128, 255, 0.24);
      outline: 0;
    }
  }

  &__inner {
    position: relative;
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;

    overflow: hidden;

    width: 100%;

    padding: rem(8px);

    border-radius: rem(8px);

    background-color: #fff;
    color: #000;
  }

  &__list {
    overflow: auto;
    max-height: rem(160px);

    padding-left: 0;

    margin: 0;

    list-style: none;

    &:focus {
      outline: 0;
    }
  }

  &__arrow {
    margin-left: auto;

    svg {
      display: inline-block;

      width: rem(8px);
      height: rem(8px);

      fill: currentColor;

      transform-origin: center;
      transition: transform 0.2s;
      vertical-align: middle;

      //stylelint-disable-next-line
      .v-select--opened & {
        transform: rotate(180deg);
      }
    }

  }

  &__option {
    position: relative;

    overflow: hidden;

    padding: rem(4px 16px);

    cursor: pointer;

    transition: color 0.3s;

    span {
      position: relative;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: $c-blue;
      content: '';
      transform: translateY(105%);
      transition: transform 0.3s;
    }

    html.desktop  &:hover:not(.v-select__option--selected) {
      color: $c-white;

      &::before {
        transform: translateY(0);
      }
    }

    &--selected {
      background-color: #ccc;
      pointer-events: none;
    }
  }
}

</style>
