<template>
  <component
    :is="componentTag"
    :type="$attrs.to || $attrs.href ? null : $attrs.buttonType || 'button'"
    :target="$attrs.target || null "
    v-bind="$attrs"
    v-on="$listeners"
    class="cta"
    :class="{icon: $attrs.icon}"
  >
    <span v-if="!$attrs.icon" class="cta-label">
      <slot/>
    </span>
    <span class="cta-icon" :class="{'ml-2': !$attrs.icon}">
      <slot name="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.336 16.589">
          <path fill="currentColor" d="M2.424 11.414.75 15.839l12.557-7.544M2.424 5.176.75.751l12.557 7.544"/>
        </svg>
      </slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'Cta',
  inheritAttrs: false,
  computed: {
    componentTag () {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href) {
        return 'a'
      }
      return 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.cta {
  position: relative;

  display: inline-flex;

  overflow: hidden;

  align-items: center;

  border: rem(2px) solid $c-white;

  appearance: none !important;
  background: $c-white;
  color: $c-blue;
  font-size: rem(12px);
  font-weight: 500;
  line-height: 1.5;
  pointer-events: auto;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateZ(0);
  transition: color 0.3s, background-color 0s;

  &:not(.icon) {
    min-width: rem(160px);
    min-height: rem(40px);

    justify-content: space-between;

    padding: rem(4px 16px);

    border-radius: rem(25px);
  }

  &.icon {
    width: rem(35px);
    height: rem(35px);

    justify-content: center;

    border-radius: 50%;

    text-align: center;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 120%;
    height: 130%;

    background: $c-blue;
    content: '';
    transform: translateY(120%) rotate(-7deg);
    transition: transform 0.3s;
  }

  html.desktop &:hover {
    background: $c-blue;
    color: $c-white;
    transition: color 0.3s, background-color 0s 0.3s;

    &::before {
      transform: translateY(-5%) rotate(0deg);
    }
  }
}

.cta-label,
.cta-icon {
  position: relative;
  z-index: 1;
}

.cta-icon {
  ::v-deep svg {
    width: 1.3em;
    height: 1.3em;
  }
}

</style>
