import packageConfig from '../package.json'
import 'what-input'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'
import isEmpty from 'lodash/isEmpty'

import router from './plugins/router.js'
// import './plugins/analytics.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import Preloader from './preloader'
import Loader from '@/atoms/Loader'
import onlineApolloProvider from './services/CMS'
import PortalVue from 'portal-vue'
import QueryString from 'query-string'
import { Viewport } from '@monogrid/vue-lib'
import GetStatus from './graphql/GetStatus.gql'
import GetGenericLabels from './graphql/GetGenericLabels.gql'
import GetQueryLanguage from '@/utils/query-language'

import throttle from 'lodash/throttle'
import ScreenQuery from './utils/screen-query'
// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)

// usage: https://github.com/LinusBorg/portal-vue
Vue.use(PortalVue)

Vue.component('portal-transition', {
  template: '<transition mode="out-in"><slot></slot></transition>'
})

Vue.component('Loader', Loader)
//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)
const parsedQuery = QueryString.parse(location.search)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  const usePreviousQueryParams = isEmpty(to.query) && !isEmpty(from.query)
  if (usePreviousQueryParams) {
    next({ ...to, query: from.query })
  } else {
    next()
  }
})

//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  mixins: [Viewport],
  /**
   * this is an example of using the online server in all the stages
   * and using offline data in a production stage (for example if deployed on client servers)
   * rename the production stage to "production" if you want to use this configuration
   */
  // apolloProvider: process.env.SERVICE_STAGE !== 'my-production-stage' ? onlineApolloProvider : new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider(),
  /**
   * use this if you want the online-only version of the CMS data
   */
  apolloProvider: onlineApolloProvider,
  apollo: {
    Status: {
      query: GetStatus,
      // pollInterval: 5000,
      variables () {
        return {
          status: 'PUBLISHED'
        }
      },
      update (data) {
        return data && data.Status ? data.Status : null
      }
    },
    GenericLabels: {
      query: GetGenericLabels,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      }
    }
  },
  data () {
    return {
      parsedQuery,
      screen: ScreenQuery(),
      preloader: preloaderInstance,
      showUI: true,
      apiDataLoaded: false,
      prevRoute: null,
      prevState: null,
      process: {
        env: process.env
      }
    }
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    },
    state () {
      return this.Status ? parsedQuery.state || this.Status.state : false
    },
    isReady () {
      return this.state && this.GenericLabels
    }
  },
  watch: {
    viewPort () {
      this.onResize()
    }
  },
  methods: {
    onResize: throttle(function () {
      this.screen = ScreenQuery()
    }, 100),
    pushAnalytics (event, data) {
      if (!window.adobeDataLayer) return
      const layer = {
        event,
        data: { ...data }
      }
      window.adobeDataLayer.push(layer)
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  }
})
