<template>
  <header
    class="flex justify-between items-center"
    :class="{'hide': !$root.showUI}"
  >
    <a
      class="logo"
      href="http://maserati.com/international"
      target="_blank"
      rel="noopener"
      title="Maserati website"
      data-linktracking="top-nav:logo"
    >
      <Logo alt="Maserati logo"/>
    </a>
    <LangSelect/>
  </header>
</template>

<script>
import LangSelect from '@/molecules/LangSelect'
import Logo from '@/assets/img/logo.svg'

export default {
  name: 'Header',
  components: {
    LangSelect,
    Logo
  }
}
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  z-index: $z-page-controls;
  top: 0;
  right: 0;
  left: 0;

  padding: rem($py-mobile $px-mobile);

  background: linear-gradient(0deg, rgba($c-black, 0) 0%, rgba($c-black, 1) 100%);

  pointer-events: none;

  transition: transform 0.3s, opacity 0.3s;

  @screen md {
    padding: rem($py-desktop $px-desktop);
  }

  > * {
    pointer-events: auto;
  }

  &.hide {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.logo {
  display: inline-block;

  color: $c-white;

  svg {
    width: rem(106px);
    height: rem(44px);
  }
}

</style>
