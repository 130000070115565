<template>
  <footer
    v-if="Footer"
    :class="{'hide': !$root.showUI, 'footer-countdown': this.$root.state === 'countdown'}"
  >
    <div class="row">
      <div class="col-sm-8 beforeSm:order-2">
        <div class="row">
          <div class="col-6 center-items">
            <div>
              <a
                v-for="({social, url}) in Footer.socials"
                :key="url"
                :href="url"
                target="_blank"
                rel="noopener"
                class="social inline-block"
                :aria-label="social"
                :data-linktracking="`footer:social:${social}`"
              >
                <component :is="getSocialComponent(social)" />
              </a>
              <a
                class="md:ml-4 block xs:inline-block"
                target="_blank"
                data-linktracking="cta:home:stay-in-touch"
                :href="Footer.stayInTouchUrl"
              >
                <small>{{Footer.stayInTouchLabel}}</small>
              </a>
            </div>
          </div>
          <div class="col-6 right-col beforeSm:text-right sm:text-center center-items">
            <div>
              <a
                :href="Footer.legalNotesUrl"
                target="_blank"
                rel="noopener"
                data-linktracking="cta:footer:legal-notes"
              >
                <small>{{Footer.legalNotesLabel}}</small>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 beforeSm:order-1 beforeSm:mb-1 beforeSm:text-center sm:text-right">
        <portal-target name="footer-right" slim transition="portal-transition"/>
      </div>
    </div>
  </footer>
</template>

<script>
import GetQueryLanguage from '@/utils/query-language'
import GetFooter from '@/graphql/GetFooter.gql'
import FacebookIcon from '@/assets/img/facebook.svg'
import YoutubeIcon from '@/assets/img/youtube.svg'
import InstagramIcon from '@/assets/img/instagram.svg'

export default {
  name: 'Footer',
  apollo: {
    Footer: {
      query: GetFooter,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      }
    }
  },
  computed: {
    socials () {
      if (!this.Footer) return []
      return this.Footer.socials.map(({ social, url }) => {
        let component
        switch (social) {
          case 'facebook':
            component = FacebookIcon
            break
          case 'instagram':
            component = InstagramIcon
            break
          case 'youtube':
            component = YoutubeIcon
            break
          default:
            component = 'div'
        }
        return { component, url }
      })
    }
  },
  methods: {
    getSocialComponent (social) {
      switch (social) {
        case 'facebook':
          return FacebookIcon
        case 'instagram':
          return InstagramIcon
        case 'youtube':
          return YoutubeIcon
        default:
          return 'div'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  z-index: $z-page-controls;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  padding: rem($py-mobile $px-mobile);

  background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);

  pointer-events: none;

  transition: transform 0.3s, opacity 0.3s;

  @screen md {
    padding: rem($py-desktop $px-desktop);
  }

  > * {
    pointer-events: auto;
  }

  &.hide {
    opacity: 0;
    transform: translateY(100%);
  }
}

.row {
  height: 100%;
}

.center-items {
  display: flex;

  width: 100%;

  align-items: center;

  > * {
    width: 100%;
  }
}

a {
  text-decoration: underline;
  transition: opacity 0.2s;

  html.desktop &:hover {
    opacity: 0.8;
  }
}

.social {
  width: rem(24px);
  height: rem(24px);

  // padding: rem(4px);

  // margin: rem(4px);

  fill: $c-white;
  text-align: center;

  &:first-child {
    margin-left: rem(-4px);
  }

  svg {
    display: inline-block;

    width: rem(16px);
    height: rem(16px);
  }
}

html.mobile .footer-countdown {
  @media (orientation: landscape) {
    .col-sm-8 {
      max-width: 100%;

      flex: 1 1 auto;

    }

    .right-col {
      text-align: right;
    }
  }
}

</style>
