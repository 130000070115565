<template>
  <div
    v-if="Background"
    class="bg full-fixed"
    role="presentation"
  >
    <transition mode="out-in">
      <video
        v-if="Background.videoBackground"
        ref="video"
        class="full-abs"
        autoplay
        muted
        playsinline
        loop
        :src="`/media/${Background.videoBackground.id}`"
        @playing="videoPlaying=true"
        @pause="videoPlaying=false"
      />
      <ResponsiveImage
        v-else-if="Background.imageBackground"
        class="full-abs"
        :data="Background.imageBackground"
        cover
        :width="$root.screen.md ? 1600 : 1200"
        :options="{format: 'jpg'}"
      />
    </transition>
    <portal to="footer-right">
      <div v-if="Background && Background.videoBackground">
        <Cta
          :icon="true"
          :aria-label="videoPlaying ? $root.GenericLabels.pauseLabel : $root.GenericLabels.playLabel"
          @click="toggleVideo"
          :data-linktracking="'cta:background:'+(videoPlaying ? $root.GenericLabels.pauseLabel : $root.GenericLabels.playLabel)"
        >
          <template #icon>
            <PlayIcon key="PlayIcon" v-if="!videoPlaying" />
            <PauseIcon key="PauseIcon" v-else/>
          </template>
        </Cta>
      </div>
    </portal>
  </div>
</template>

<script>
import GetQueryLanguage from '@/utils/query-language'
import GetBackground from '@/graphql/GetBackground.gql'
import bgImage from '@/assets/img/background.jpg'
import PlayIcon from '@/assets/img/play.svg'
import PauseIcon from '@/assets/img/pause.svg'
import Cta from '@/atoms/Cta'
import ResponsiveImage from '@/atoms/ResponsiveImage'

export default {
  name: 'Background',
  components: { Cta, PlayIcon, PauseIcon, ResponsiveImage },
  apollo: {
    Background: {
      query: GetBackground,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      }
    }
  },
  data () {
    return {
      bgImage,
      videoPlaying: false
    }
  },
  methods: {
    onPlay () {
      this.videoPlaying = true
    },
    onPause () {
      this.videoPlaying = false
    },
    toggleVideo () {
      this.$refs.video[this.videoPlaying ? 'pause' : 'play']()
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  z-index: 0;
}

img,
video {
  width: 100%;
  height: 100%;

  object-fit: cover;
}
</style>
