<template>
  <div id="app" class="app" v-if="$root.isReady">
    <Header />
    <transition mode="out-in">
      <Background v-if="$root.state !== 'live'" />
    </transition>
    <transition mode="out-in">
      <keep-alive include="router-view">
        <component
          class="page"
          :is="pageComponent"
          :key="$route.name+($route.params.slug || '')"
        />
      </keep-alive>
    </transition>
    <Footer />
    <portal-target name="modal" slim transition="portal-transition"/>
    <VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    />
  </div>
</template>

<script>
import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import ShareMixin from '@/mixins/share-mixin'
import Background from '@/atoms/Background'
import Loader from '@/atoms/Loader'
import Header from '@/organisms/Header'
import Footer from '@/organisms/Footer'
import ShareImg from '@/assets/img/share.jpeg'

let initializedModernizr = false

export default {
  name: 'App',
  mixins: [ShareMixin],
  components: {
    Header,
    Footer,
    Background,
    Loader,
    VersionInfo
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      this.$root.platform.desktop = !this.$root.platform.mobile && !this.$root.platform.tablet
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  computed: {
    pageComponent () {
      if (!this.$root.Status) {
        return 'div'
      }
      switch (this.$root.state) {
        case 'countdown':
          return () => import(/* webpackChunkName: "page-C" */ '@/pages/CountdownPage')
        case 'live':
          return () => import(/* webpackChunkName: "page-L" */ '@/pages/LivePage')
        case 'post':
          return 'router-view'
        default:
          return 'div'
      }
    }
  },
  mounted () {
    this.$events.on('toggle-ui', this.onToggleUI)
  },
  watch: {
    '$root.Status' (current, prev) {
      if (current !== prev) {
        this.onToggleUI(true)
        if (prev && prev.state) {
          this.$root.prevState = prev.state
        }
      }
      if (current.state !== 'post') {
        this.$router.replace({ name: 'Home' }).catch(() => {})
      }
    },
    '$route' (current, prev) {
      this.onToggleUI(true)
      this.$root.prevRoute = prev
    }
  },
  methods: {
    onToggleUI (showUI) {
      if (this.$root.showUI === showUI) return
      this.$root.showUI = showUI === undefined ? !this.$root.showUI : showUI
    }
  },
  metaInfo () {
    const title = this.$root.Settings ? this.$root.Settings.title : 'Grecale | Global Digital Premiere'

    const baseShare = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - ${title}` : title),
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@maserati_hq' },
        { name: 'twitter:creator', content: '@maserati_hq' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: title },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + this.$route.fullPath },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + ShareImg },
        { vmid: 'description', name: 'description', content: 'The Maserati of SUVs accelerates into the future' },
        { vmid: 'ogdescription', property: 'og:description', content: 'The Maserati of SUVs accelerates into the future' },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1920' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '1080' }

      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }

    const share = { meta: [] }

    return {
      ...baseShare,
      ...share,
      meta: [
        ...baseShare.meta,
        ...share.meta
      ]
    }
  }
}
</script>

<style lang="scss">
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  @include generate-grid();
  @import 'swiper/swiper';

  @font-face {
    font-display: swap;
    font-family: 'Everett';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Everett-Regular.woff2') format('woff2'),
      url('assets/fonts/Everett-Regular.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Everett';
    font-style: normal;
    font-weight: bold;
    src: url('assets/fonts/Everett-Bold.woff2') format('woff2'),
      url('assets/fonts/Everett-Bold.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Everett';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/Everett-Light.woff2') format('woff2'),
      url('assets/fonts/Everett-Light.woff') format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Anko';
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/Anko-Regular.woff2') format('woff2'),
      url('assets/fonts/Anko-Regular.woff') format('woff');
  }
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none;
}

::selection {
  background-color: $c-blue;
  color: $c-white;
}

.swiper .swiper-notification {
  position: absolute;
  z-index: -1000;
  top: 0;
  left: 0;

  opacity: 0;
  pointer-events: none;
}

html,
body {
  font-family: 'Everett';
  overscroll-behavior: none;
}

.page {
  padding: rem(0 $px-mobile);

  @screen md {
    padding: rem(0 $px-desktop);
  }
}

h1 {
  font-size: rem(36px);
  line-height: 1;
  @screen sm {
    font-size: rem(48px);
  }
  @screen md {
    font-size: rem(56px);
  }
  @screen lg {
    font-size: rem(70px);
  }
}

h2 {
  font-size: rem(20px);
  line-height: rem(24px);
  @screen sm {
    font-size: rem(26px);
    line-height: rem(28px);
  }
}

h3 {
  font-size: rem(20px);
  line-height: rem(24px);
  @screen sm {
    font-size: rem(24px);
    line-height: rem(30px);
  }
  @screen lg {
    font-size: rem(30px);
    line-height: rem(34px);
  }
}

h4 {
  font-size: rem(16px);
  line-height: rem(20px);
}

h5 {
  font-size: rem(16px);
  line-height: rem(19px);
}

h6 {
  font-size: rem(13px);
  line-height: rem(13px);
}

.grecale {
  display: inline-block;

  width: 80%;
  max-width: rem(300px);
}

.modal {
  z-index: $z-modal;

  display: flex;

  align-items: center;

  background: $c-black;
}

.close-modal {
  position: fixed;
  z-index: 2;
  top: rem(16px);
  right: rem(16px);

  width: rem(32px);
  height: rem(32px);

  svg {
    width: 100%;
    height: 100%;
  }

  span {
    display: none;

    width: 0;
    height: 0;

    visibility: hidden;
  }
}

.full-abs,
.full-fixed {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-abs {
  position: absolute;
}

.full-fixed {
  position: fixed;
}

.v-enter-active,
.v-leave-active,
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.v-enter,
.v-leave-to,
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
