<template>
  <div class="loader">
    <svg viewBox="25 25 50 50" >
      <circle cx="50" cy="50" r="20" fill="none" stroke="currentColor" stroke-width="2" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;

  width: rem(50px);
  height: rem(50px);

  margin-top: rem(-25px);
  margin-left: rem(-25px);

  color: $c-white;
}

svg {
  width: 100%;
  height: 100%;

  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

circle {
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  stroke-linecap: round;
}

@keyframes rotate {
  100% { transform: rotate(360deg);}
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
</style>
