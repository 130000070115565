<template>
  <StyledSelect
    class="langSelect"
    v-model="value"
    :options="options"
    data-linktracking="top-nav:language"
  >
    <template v-slot:selected="{ value }">{{ value.toUpperCase() }}</template>
  </StyledSelect>
</template>

<script>
import StyledSelect from '@/atoms/StyledSelect.vue'
import availableLanguages from '@/assets/data/availableLanguages.json'

export default {
  name: 'LangSelect',
  components: { StyledSelect },
  data () {
    return {
      value: this.$language.current
    }
  },
  computed: {
    options () {
      return Object.entries(availableLanguages).map(([value, label]) => ({ value, label }))
    }
  },
  watch: {
    value (language) {
      document.documentElement.setAttribute('lang', language)
      if (this.$route.params.language !== language) {
        this.$router.replace({ params: { language }, query: this.$route.query })
      }
    },
    '$language.current' () {
      if (this.$language.current !== this.value) {
        this.value = this.$language.current
      }
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-select__menu {
    right: 0;
    left: auto;

    min-width: rem(120px);

    padding: 0;

    font-size: rem(14px);

  }

  .v-select__btn {
    position: relative;

    overflow: hidden;

    min-width: rem(50px);

    border-radius: rem(20px);

    background: $c-white;

    color: $c-blue;

    > * {
      position: relative;
    }

    &::before {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;

      width: 120%;
      height: 130%;

      background: $c-blue;
      content: '';
      transform: translateY(120%) rotate(-7deg);
      transition: transform 0.3s;
    }
  }
}

.langSelect {
  html.desktop & {
    // stylelint-disable-next-line
    ::v-deep .v-select__btn:hover {
      background: $c-blue;
      color: $c-white;
      transition: color 0.3s, background-color 0s 0.3s;

      // stylelint-disable-next-line
      &::before {
        transform: translateY(-5%) rotate(0deg);
      }
    }
  }
}

</style>
