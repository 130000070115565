const pxToRem = (px) => {
  return `${px / 16}rem`
}

module.exports = {
  purge: {
    preserveHtmlElements: true,
    content: [
      './src/**/*.html',
      './src/**/*.js',
      './src/**/*.vue'
    ]
  },
  corePlugins: [
    'preflight',
    'margin',
    'padding',
    'textAlign',
    'display',
    'flex',
    'flexDirection',
    'flexWrap',
    'flexGrow',
    'flexShrink',
    'order',
    'justifyContent',
    'justifyItems',
    'justifySelf',
    'alignContent',
    'alignItems',
    'alignSelf',
    'textTransform',
    'position'
  ],
  darkMode: false,
  theme: {
    screens: {
      beforeXs: { max: pxToRem(399) },
      xs: pxToRem(400),

      beforeSm: { max: pxToRem(639) },
      sm: pxToRem(640),
      // => @media (min-width: 640px) { ... }
      beforeMd: { max: pxToRem(767) },
      md: pxToRem(768),
      // => @media (min-width: 768px) { ... }
      beforeLg: { max: pxToRem(1023) },
      lg: pxToRem(1024),
      // => @media (min-width: 1024px) { ... }
      beforeXl: { max: pxToRem(1279) },
      xl: pxToRem(1280),
      // => @media (min-width: 1280px) { ... }
      before2xl: { max: pxToRem(1535) },
      '2xl': pxToRem(1536)
      // => @media (min-width: 1536px) { ... }
    }
  },
  variants: {},
  plugins: []
}
